 .fc-day-mon,
 .fc-day-tue,
 .fc-day-wed,
 .fc-day-thu,
 .fc-day-fri,
 .fc th {
     background-color: white;
}
/* Define the dot styles */
.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.dot.blue {
    background-color: blue;
}

.dot.green {
    background-color: green;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    height: 200px;
    padding-left: 30px;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
    height: 200px;
    padding-left: 30px;
}
